function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_APP = '/app';
const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: ROOTS_AUTH,
};

export const PATH_APP = {
  root: ROOTS_APP,
  home: path(ROOTS_APP, '/home'),
  conversations: {
    root: path(ROOTS_APP, '/conversations'),
    view: (matchId) => path(ROOTS_APP, `/conversations/${matchId}`),
  },
  profile: {
    root: path(ROOTS_APP, '/profile'),
    view: (username) => path(ROOTS_APP, `/profile/${username}`),
  },
  settings: path(ROOTS_APP, '/settings'),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
};

export const PATH_PAGE = {
  page403: '/403',
  noAccessProfilePage: '/no-profile-access',
  noMatchFoundPage: '/no-match-found',
  home: '/',
};
