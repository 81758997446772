import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { pxToRem } from '../../../theme/typography';

MobileBottomBar.propTypes = {
    children: PropTypes.node,
};

export default function MobileBottomBar({ children }) {
    return (
        <StyledBottomBar data-qa-id="mobile-bottom-bar">
            <Box sx={{ padding: pxToRem(16) }}>
                {children}
            </Box>
        </StyledBottomBar>
    );
}

const StyledBottomBar = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    backgroundColor: "#FFEFB7",
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: pxToRem(2),
        backgroundColor: "#E5B77E",
    },
}));
