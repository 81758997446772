import PropTypes from 'prop-types';
import useResponsive from '../../hooks/useResponsive';
import { IconButton, Typography } from '@mui/material';
import Iconify from '../iconify/Iconify';

UniversalBackButton.propTypes = {
    onClose: PropTypes.func,
    customButtonText: PropTypes.string,
    customStyles: PropTypes.object,
    closeIconKey: PropTypes.string,
};

export default function UniversalBackButton({ onClose, customButtonText, customStyles, closeIconKey }) {
    const isDesktop = useResponsive('up', 'lg');
    return (
        <IconButton
            color="inherit"
            size={isDesktop ? "large" : "medium"}
            onClick={onClose}
            sx={{
                ...customStyles
            }}
        >
            {closeIconKey ? <Iconify icon={closeIconKey} /> : <Iconify icon="ion:chevron-back" />}
            {customButtonText && (
                <Typography sx={{ color: 'text.secondary' }}>
                    {customButtonText}
                </Typography>
            )}
        </IconButton>

    )
}





