import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Card, styled } from '@mui/material';
import { pxToRem } from '../../theme/typography';
import useResponsive from '../../hooks/useResponsive';

const AuthModal = ({ open, onClose, children, customStyles }) => {
    const isDesktop = useResponsive('up', 'lg');

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            fullScreen={!isDesktop}
            PaperProps={{
                sx: {
                    overflow: 'hidden', // Ensure no horizontal overflow
                    ...customStyles,
                },
            }}
            data-qa-id={"auth-modal"}
        >
            <StyledCard isDesktop={isDesktop} data-qa-id={"auth-modal-card"}>
                {children}
            </StyledCard>
        </Dialog>
    );
};

AuthModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
    customStyles: PropTypes.object,
};

const StyledCard = styled(Card)(({ theme, isDesktop }) => ({
    maxWidth: pxToRem(800), // Increase the width of the card
    margin: 'auto',
    borderRadius: isDesktop ? theme.shape.borderRadius * 2 : 0,
    boxShadow: theme.shadows[0],
    height: isDesktop ? "inherit" : "100%",
    width: '100%', // Ensure it takes full width
    backgroundColor: theme.palette.background.paper,
    zIndex: 1, // Ensure card is above the highlight wrapper
    border: isDesktop ? `${pxToRem(2)} solid ${theme.palette.card.borderStroke}` : "inherit",
}));


export default AuthModal;
