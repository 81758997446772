import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar } from '@mui/material';
import { observer } from 'mobx-react';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
// navigation config
import { authenticatedNavConfigDesktop, authenticatedNavConfigMobile, nonAuthenticatedNavConfig } from './config-navigation';
// auth context
import { useAuthContext } from '../../../auth/useAuthContext';
import useResponsive from '../../../hooks/useResponsive';
import { pxToRem } from '../../../theme/typography';

// ----------------------------------------------------------------------

const NavHorizontal = observer(({ unreadMessageCount }) => {
  const theme = useTheme();
  const { isAuthenticated } = useAuthContext();

  const isDesktop = useResponsive("up", "lg");

  const navConfig = isAuthenticated ? (isDesktop ? authenticatedNavConfigDesktop : authenticatedNavConfigMobile) : nonAuthenticatedNavConfig;

  return (
    <AppBar
      component="nav"
      color="transparent"
      sx={{
        boxShadow: 0,
        bottom: 0,
        top: 'inherit',
      }}
      data-qa-id="horizontal-bottom-navigation-bar"
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal data={navConfig} unreadMessageCount={unreadMessageCount} sx={{ pb: pxToRem(8), }} />
      </Toolbar>

      <Shadow />
    </AppBar>
  );
});

NavHorizontal.propTypes = {
  unreadMessageCount: PropTypes.number,
};

export default memo(NavHorizontal);

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
