import dayjs from 'dayjs';

export const calculateAge = (dob) => {
    const today = dayjs();

    const birthDate = dayjs(dob);  // create a date object directly from `dob` argument

    let ageNow = today.year() - birthDate.year();

    const monthDiff = today.month() - birthDate.month();
    if (monthDiff < 0 || (monthDiff === 0 && today.date() < birthDate.date())) {
        ageNow--;
    }

    return ageNow;
}

// returns how much time to a specific time. If that time is reached or passed, it will return 0
export const timeTo = (time, isDesktop = true) => {
    const expirationTime = dayjs(time);

    // Calculate the difference between now and the expiration time
    const diffInMilliseconds = expirationTime.diff(dayjs());

    // Convert the difference to hours, minutes, and seconds
    const hours = Math.floor(diffInMilliseconds / 3600000); // 1 hour = 3,600,000 milliseconds
    const minutes = Math.floor((diffInMilliseconds % 3600000) / 60000); // 1 minute = 60,000 milliseconds
    const seconds = Math.floor((diffInMilliseconds % 60000) / 1000); // 1 second = 1,000 milliseconds

    // For desktop view, we want the full "xh ym zs" format.
    if (isDesktop) {
        let remainingTimeDesktop = "";
        if (hours > 0) {
            remainingTimeDesktop += `${hours}h`;
        }
        if (minutes > 0) {
            remainingTimeDesktop += ` ${minutes}m`;
        }
        if (seconds > 0) {
            remainingTimeDesktop += ` ${seconds}s`;
        }

        return { formatted: remainingTimeDesktop.trim(), value: diffInMilliseconds <= 0 ? 0 : diffInMilliseconds };
    }

    // For mobile view, we want the "hh:mm:ss" format like in the screenshot.
    const formattedTimeMobile = `${String(hours).padStart(1, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return { formatted: formattedTimeMobile, value: diffInMilliseconds <= 0 ? 0 : diffInMilliseconds };
};

