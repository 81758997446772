// utils/streakSummaryContent.js

const streakMessages = {
    0: "Today is a good day to begin a new streak. Build your streak by answering today's question.",
    1: "You're off to a great start! Keep it up.",
    2: "You're gaining momentum. Let's go!",
    3: "Day 3 and you're just getting started!",
    4: "Props to you for staying consistent. How high can you go?",
    5: "5 days in a row! You're on fire!",
    6: "You're doing amazing. Keep that streak alive!",
    7: "A full week of consistency! Great job!",
    8: "You’re on a roll! Let’s keep it going.",
    9: "Almost to double digits! Stay strong!",
    10: "10 days straight! Fantastic effort!",
    11: "Keep it up! You’re doing great.",
    12: "12 days of commitment! Wonderful work.",
    13: "Lucky 13! Keep pushing forward.",
    14: "Two weeks strong! Awesome job!",
    15: "Halfway to 30 days! Keep going!",
    16: "You’re unstoppable! Keep it up.",
    17: "17 days of dedication! Amazing!",
    18: "18 days in a row! Superb effort!",
    19: "You're on a mission! Keep at it.",
    20: "20 days straight! Outstanding!",
    21: "Three weeks of greatness! Well done!",
    22: "You’re making great progress! Keep it going.",
    23: "23 days and counting! Keep up the fantastic work.",
    24: "24 days of perseverance! Amazing job!",
    25: "A quarter way to 100 days! Excellent!",
    26: "26 days straight! Keep it rolling.",
    27: "You’re a streak champion! Keep it up.",
    28: "28 days of consistency! Awesome!",
    29: "Almost at 30! Keep pushing!",
    30: "30 days! A full month of dedication!",
    31: "You’re doing great! Keep the streak alive.",
    32: "32 days of focus! Fantastic!",
    33: "33 days straight! You’re unstoppable.",
    34: "34 days of progress! Keep it up.",
    35: "35 days! You're on a roll!",
    36: "36 days of hard work! Well done!",
    37: "37 days of commitment! Keep going!",
    38: "38 days straight! Superb!",
    39: "39 days of effort! Awesome job!",
    40: "40 days! You’re doing fantastic!",
    41: "41 days of success! Keep it going!",
    42: "42 days of greatness! Keep it up.",
    43: "43 days straight! Wonderful!",
    44: "44 days of dedication! Well done!",
    45: "45 days! Keep the momentum going!",
    46: "46 days of effort! Amazing!",
    47: "47 days straight! You’re doing great.",
    48: "48 days of consistency! Keep it up.",
    49: "49 days of commitment! Superb!",
    50: "50 days! Halfway to 100!",
  };
  
  const getStreakSummaryContent = (currentStreak) => {
    if (currentStreak > 50) {
      return "You're beyond amazing! Keep up the extraordinary work!";
    }
    return streakMessages[currentStreak] || "Keep the streak going strong!";
  };
  
  export default getStreakSummaryContent;
  