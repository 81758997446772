import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import App from './App';
import posthog from 'posthog-js';
import { AuthProvider } from './auth/JwtContext';
import { useAuthContext } from './auth/useAuthContext'; // Ensure correct path

// ----------------------------------------------------------------------

const getDomain = () => {
    if (process.env.REACT_APP_VERCEL_ENV === "production") {
        return process.env.REACT_APP_FRONTEND_DOMAIN_PRODUCTION;
    } else if (process.env.REACT_APP_VERCEL_ENV === "preview") {
        return process.env.REACT_APP_FRONTEND_DOMAIN_PREVIEW;
    }
    return "localhost";
};

const domain = getDomain();

const initializeSentry = (isAuthenticated) => {
    if (!isAuthenticated) return;

    if (process.env.REACT_APP_VERCEL_ENV === "production" || process.env.REACT_APP_VERCEL_ENV === "preview") {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
                Sentry.feedbackIntegration({
                    colorScheme: "system",
                    showBranding: false,
                    triggerLabel: "Feedback",
                    formTitle: "Report a bug or feedback",
                    submitButtonLabel: "Submit",
                    messagePlaceholder: "Describe the issue or feedback.",
                }),
            ],
            tracesSampleRate: 1.0,
            tracePropagationTargets: [
                new RegExp(`^https://${domain}/api`),
            ],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }
};

const initializePostHog = () => {
    if (process.env.REACT_APP_VERCEL_ENV === "production" || process.env.REACT_APP_VERCEL_ENV === "preview") {
        posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
            api_host: 'https://us.i.posthog.com',
            autocapture: false,
            capture_pageview: true,
            disable_session_recording: true,
            loaded: (posthog) => {
                posthog.register({
                    environment: process.env.REACT_APP_VERCEL_ENV,
                });
            },
        });
    }
};

const RootComponent = () => {
    const { isAuthenticated } = useAuthContext();

    useEffect(() => {
        initializeSentry(isAuthenticated);
        initializePostHog();
    }, [isAuthenticated]);

    return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthProvider>
        <RootComponent />
    </AuthProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
