import { alpha } from '@mui/material/styles';
import { pxToRem } from '../typography';

export default function IconButton(theme) {
    const isLight = theme.palette.mode === 'light';

    const rootStyle = (ownerState) => {
        const containedVariant = ownerState.variant === 'contained';
        const outlinedVariant = ownerState.variant === 'outlined';
        const smallSize = ownerState.size === 'small';
        const largeSize = ownerState.size === 'large';

        const highlightWrapper = {
            '&::before': {
                content: '""',
                position: 'absolute',
                bottom: -3,
                left: 0,
                right: 0,
                height: 8,
                backgroundColor: theme.palette.button[containedVariant ? "containedBorderStroke" : "outlinedBorderStroke"],
                borderBottomLeftRadius: theme.shape.borderRadius * 2,
                borderBottomRightRadius: theme.shape.borderRadius * 2,
                zIndex: -1,

            },
        };

        const highlightHoverWrapper = {
            '&::before': {
                content: '""',
                position: 'absolute',
                bottom: -3,
                left: 0,
                right: 0,
                height: 8,
                backgroundColor: theme.palette.button.hover[containedVariant ? "containedBorderStroke" : "outlinedBorderStroke"],
                borderBottomLeftRadius: theme.shape.borderRadius * 2,
                borderBottomRightRadius: theme.shape.borderRadius * 2,
                zIndex: -1,

            },
        };

        // apply when button pressed
        const highlightActiveWrapper = {
            '&::before': {
                content: '""',
                position: 'absolute',
                bottom: -3,
                left: 0,
                right: 0,
                height: 0,
                backgroundColor: theme.palette.button[containedVariant ? "containedBorderStroke" : "outlinedBorderStroke"],
                borderBottomLeftRadius: theme.shape.borderRadius * 2,
                borderBottomRightRadius: theme.shape.borderRadius * 2,
                zIndex: -1,
            },
        };

        const disabledHighlightWrapper = {
            '&::before': {
                content: '""',
                position: 'absolute',
                bottom: -3,
                left: 0,
                right: 0,
                height: 8,
                backgroundColor: theme.palette.button.disabled[containedVariant ? "containedBorderStroke" : "outlinedBorderStroke"],
                borderBottomLeftRadius: theme.shape.borderRadius * 2,
                borderBottomRightRadius: theme.shape.borderRadius * 2,
                zIndex: -1,
            },
        };

        const burgundyDisabledPrimaryStyle = {
            ...(containedVariant && {
                ...disabledHighlightWrapper,
                backgroundColor: theme.palette.button.disabled.containedBackground,
                color: theme.palette.common.white,
            }),
            ...(outlinedVariant && {
                ...disabledHighlightWrapper,
                backgroundColor: theme.palette.button.disabled.outlinedBackground,
                borderColor: theme.palette.secondary.contrastText,
                color: theme.palette.secondary.contrastText,
            }),
        };

        const burgundyPrimaryStyle = {
            ...(containedVariant && {
                ...highlightWrapper,
                backgroundColor: theme.palette.button.containedBackground,
                color: theme.palette.common.white,
                '&:hover': {
                    ...highlightHoverWrapper,
                    backgroundColor: theme.palette.button.hover.containedBackground,
                    boxShadow: 'inherit',
                },
                '&:active': {
                    ...highlightActiveWrapper,
                    transform: `translateY(${pxToRem(4)}) translateZ(0)`,
                },
            }),
            ...(outlinedVariant && {
                ...highlightWrapper,
                backgroundColor: theme.palette.button.outlinedBackground,
                borderColor: theme.palette.secondary.contrastText,
                color: theme.palette.secondary.contrastText,
                '&:hover': {
                    ...highlightHoverWrapper,
                    backgroundColor: theme.palette.button.hover.outlinedBackground,
                    borderColor: theme.palette.secondary.contrastText,
                    color: theme.palette.secondary.contrastText,
                },
                '&:active': {
                    ...highlightActiveWrapper,
                    transform: `translateY(${pxToRem(4)}) translateZ(0)`,
                },
            }),
        };

        const disabledState = {
            '&.Mui-disabled': {
                ...burgundyDisabledPrimaryStyle
            },
        };

        const size = {
            ...(smallSize && {
                width: 30,
                height: 30,
            }),
            ...(largeSize && {
                width: 48,
                height: 48,
            }),
        };

        return [burgundyPrimaryStyle, disabledState, size];
    };

    return {
        MuiIconButton: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyle(ownerState),
            },
        },
    };
}
