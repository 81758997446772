// @mui
import { styled, alpha } from '@mui/material/styles';
import { Popover, ListItemButton, ListItemIcon, Badge } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// config
import { NAV, ICON } from '../../../config-global';
import { pxToRem } from '../../../theme/typography';

// ----------------------------------------------------------------------

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'open' && prop !== 'variant',
})(({ active, disabled, open, depth, variant, theme }) => {
  const isLight = theme.palette.mode === 'light';

  const subItem = depth !== 1;

  const containedVariant = variant === 'contained';
  const outlinedVariant = variant === 'outlined';

  const burgundyDisabledPrimaryStyle = {
    ...(containedVariant && {
      backgroundColor: theme.palette.button.disabled.containedBackground,
      color: theme.palette.common.white,
    }),
    ...(outlinedVariant && {
      backgroundColor: theme.palette.button.disabled.outlinedBackground,
      borderColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.contrastText,
    }),
  };

  const activeStyle = {
    ...(containedVariant && {

      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[0],
      background: theme.palette.background.lightNeutral,
      zIndex: 1,
      border: `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`,
      paddingLeft: pxToRem(14), // undoes extra padding from border
      backgroundColor: theme.palette.button.hover.containedBackground,
      color: theme.palette.common.white,
    }),
    ...(outlinedVariant && {
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[0],
      background: theme.palette.background.lightNeutral,
      zIndex: 1,
      border: `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`,
      paddingLeft: pxToRem(14), // undoes extra padding from border
      backgroundColor: theme.palette.button.hover.outlinedBackground,
      color: theme.palette.secondary.contrastText,

    }),
    ...(!isLight && {
      color: theme.palette.primary.light,
    }),
  };

  const activeHoverStyle = {
    ...(containedVariant && {
      backgroundColor: theme.palette.button.hover.containedBackground,
      boxShadow: 'inherit',
    }),
    ...(outlinedVariant && {
      backgroundColor: theme.palette.button.hover.outlinedBackground,
      color: theme.palette.secondary.contrastText,
    }),
    ...(!isLight && {
      color: theme.palette.primary.light,
    }),
  };

  const inActiveHoverStyle = {
    ...(containedVariant && {
      backgroundColor: theme.palette.button.hover.containedBackground,
      color: theme.palette.common.white,

    }),
    ...(outlinedVariant && {
      backgroundColor: theme.palette.button.hover.outlinedBackground,
      color: theme.palette.secondary.contrastText,
    }),
    ...(!isLight && {
      color: theme.palette.primary.light,
    }),
  };

  const activeSubStyle = {
    ...activeHoverStyle
  };

  return {
    flexShrink: 0,
    display: 'inline-flex',
    textTransform: 'capitalize',
    padding: `${theme.spacing(0, 0.75)} !important`,
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    height: NAV.H_DASHBOARD_ITEM_HORIZONTAL,
    '&:hover': inActiveHoverStyle,
    // Sub item
    ...(subItem && {
      width: '100%',
      margin: 0,
      paddingRight: 0,
      paddingLeft: theme.spacing(1),
    }),
    // Active item
    ...(active && {
      ...activeStyle,
      '&:hover': {
        ...activeHoverStyle,
      },
    }),
    // Active sub item
    ...(subItem &&
      active && {
      ...activeSubStyle,
      '&:hover': {
        ...activeHoverStyle,
      },
    }),
    // Open
    ...(open && !active && activeHoverStyle),
    // Disabled
    ...(disabled && {
      '&.Mui-disabled': {
        ...burgundyDisabledPrimaryStyle,
      },
    }),
  };
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 8,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}));

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
  marginRight: 8,
  flexShrink: 0,
  width: ICON.NAV_ITEM_HORIZONTAL,
  height: ICON.NAV_ITEM_HORIZONTAL,
});

// ----------------------------------------------------------------------

export const StyledPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    width: 160,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    boxShadow: theme.customShadows.dropdown,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    ...bgBlur({ color: theme.palette.background.default }),
  },
}));
