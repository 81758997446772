import { useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
//
import { useAuthContext } from './useAuthContext';
// components
import LoadingScreen from '../components/loading-screen/LoadingScreen';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const [loadingComplete, setLoadingComplete] = useState(false);
  
  const navigate = useNavigate();
  
  const { pathname } = useLocation();
  
  const [requestedLocation, setRequestedLocation] = useState(null);
  
  const handleLoadingComplete = () => {
    setLoadingComplete(true);
  };

  if (!isInitialized || !loadingComplete) {
    console.log("AUTH GUARD LOADING")
    return <LoadingScreen onLoadingComplete={handleLoadingComplete} />
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    navigate(PATH_AUTH.root, { replace: true });

    return;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
