import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { DEFAULT_STEP, REGISTRATION_STEP } from './constants';
// utils
import axios from '../../../utils/axios';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useStoresContext } from '../../../stores/useStoresContext';
import LoadingScreen from '../../../components/loading-screen/LoadingScreen';
import { handleError } from '../../../utils/handleError';

const defaultState = {
    step: REGISTRATION_STEP.NAME_FORM,
}

const defaultContext = {
    previousStep: () => null,
    state: defaultState,
    setStep: () => null,
    updateFields: () => null,
}

const filename = "/sections/auth/register/RegistrationContext.js";

export const RegistrationContext = createContext(defaultContext);

export const RegistrationProvider = ({ children }) => {
    const [state, setState] = useState(defaultState);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(true);

    const { login, register } = useAuthContext();
    const { layoutStore } = useStoresContext();
    
    useEffect(() => {
        if (state.step > REGISTRATION_STEP.NAME_FORM) {
            completeUserOnboarding();
        }
    }, [state.step]);
    
    const handleLoadingComplete = () => {
      setLoadingComplete(true);
    };

    const previousStep = () => {
        if (!state.step) {
            state.step = DEFAULT_STEP;
        }

        let newStep = state.step - 1;

        setState({
            ...state,
            step: newStep > REGISTRATION_STEP.NAME_FORM ? newStep : REGISTRATION_STEP.NAME_FORM,
        });
    }

    const updateFields = (updateBody) => {
        if (!state.step) {
            state.step = DEFAULT_STEP;
        }

        let newStep = state.step + 1;

        setState({
            ...state,
            ...(updateBody || {}),
            step: newStep,
        });
    }

    const setStep = (step) => {
        setState({
            ...state,
            step,
        });
    }

    const completeUserOnboarding = async () => {
        setIsLoading(true);
        setLoadingComplete(false);
        try {
            const { first_name, phone } = state;
            
            await register({ phone, first_name });

            await login();
            
            layoutStore.closeAuthModal(); // Ensure the modal is closed after login
        } catch (err) {
            handleError({
                error: err,
                message: `Error completing user onboarding with phone`,
                filename,
                functionName: "completeUserOnboarding",
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <RegistrationContext.Provider
            value={{
                state,
                previousStep,
                updateFields,
                setStep,
            }}
        >
            {isLoading || !loadingComplete ? <LoadingScreen onLoadingComplete={handleLoadingComplete} /> : children}
        </RegistrationContext.Provider>
    );
};

RegistrationProvider.propTypes = {
    children: PropTypes.node,
};
