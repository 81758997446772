import PropTypes from 'prop-types';
import { memo } from 'react';
import { Box, Stack, Divider, Drawer } from '@mui/material';
import { observer } from 'mobx-react';
// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import NavToggleButton from './NavToggleButton';
// navigation config
import { authenticatedNavConfigDesktop, authenticatedNavConfigMobile, nonAuthenticatedNavConfig } from './config-navigation';
// auth context
import { useAuthContext } from '../../../auth/useAuthContext';
import { pxToRem } from '../../../theme/typography';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const NavVertical = observer(({ unreadMessageCount, openNav, onToggleNav }) => {
  const { isAuthenticated } = useAuthContext();
  const isDesktop = useResponsive('up', 'lg');

  const navConfig = isAuthenticated ? (isDesktop ? authenticatedNavConfigDesktop : authenticatedNavConfigMobile) : nonAuthenticatedNavConfig;

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          alignItems: openNav ? 'flex-start' : 'center',
        }}
      >
        <Logo logoTextSX={{ pt: `${pxToRem(6)}` }} openNav={openNav} />
        <Divider />
      </Stack>

      <NavSectionVertical data={navConfig} unreadMessageCount={unreadMessageCount} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: openNav ? NAV.W_DASHBOARD : NAV.W_DASHBOARD_MINI },
        transition: 'width 0.3s',
      }}
    >
      <NavToggleButton openNav={openNav} onToggleNav={onToggleNav} />

      <Drawer
        open
        variant="permanent"
        PaperProps={{
          sx: {
            zIndex: 0,
            width: openNav ? NAV.W_DASHBOARD : NAV.W_DASHBOARD_MINI,
            bgcolor: 'background.paper',
            borderRightStyle: 'dashed',
            transition: 'width 0.3s',
          },
        }}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
});

NavVertical.propTypes = {
  unreadMessageCount: PropTypes.number,
  openNav: PropTypes.bool,
  onToggleNav: PropTypes.func,
};

export default memo(NavVertical);
