import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { AppBar, Box } from '@mui/material';
import { HEADER } from '../../config-global';
import UniversalBackButton from '../universal-back-button/UniversalBackButton';
import { useTheme } from '@mui/material/styles';

CustomHeader.propTypes = {
    onClose: PropTypes.func,
    closeIconKey: PropTypes.string,
    customStyles: PropTypes.object,
    titleNode: PropTypes.element,
};

export default function CustomHeader({ onClose, titleNode, closeIconKey, customStyles }) {
    const theme = useTheme();

    return (
        <Header
            sx={{
                height: HEADER.H_MOBILE,
                backgroundColor: "inherit",
                boxShadow: "none",
                borderBottom: 'none',
                color: "inherit",
                ...customStyles
            }}
            data-qa-id={"custom-header-container"}
        >
            <HeaderContent data-qa-id={"custom-header-content-container"}>
                <RightContainer data-qa-id={"right-container"}>
                    {titleNode}
                    {onClose && (
                        <UniversalBackButton
                            onClose={onClose}
                            closeIconKey={closeIconKey}
                            customStyles={{ color: theme.palette.primary.main, opacity: 0.5, position: "absolute", right: 0 }}
                        />
                    )}
                </RightContainer>
            </HeaderContent>
        </Header>
    );
}

const Header = styled(AppBar)({
    minHeight: "60px",
    justifyContent: "center",
    position: "sticky",
});

const HeaderContent = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
});

const RightContainer = styled(Box)({
    display: "flex",
    alignItems: "baseline",
    paddingRight: "4px",
    width: "100%",
    justifyContent: "space-between",
});
