// handleError.js
import * as Sentry from "@sentry/react";

export const handleError = ({ error, message, filename, functionName }) => {
    const environment = process.env.REACT_APP_VERCEL_ENV;

    if (environment === "production" || environment === "preview") {
        Sentry.captureException(error, {
            extra: {
                message,
                environment,
                filename,
                function: functionName,
            },
        });
    } else {
        console.error(`ClientLogger: Error in ${functionName} in ${filename}`, {
            filename: filename,
            function: functionName,
            message: message,
            rawError: error,
        });
    }
};
