import axios from '../utils/axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../components/loading-screen/LoadingScreen';
import { useAuthContext } from './useAuthContext';
import { handleError } from "../utils/handleError";

const filename = "/auth/AuthGoogleCallback.js";

const AuthGoogleCallback = () => {
    const navigate = useNavigate();
    const { login } = useAuthContext();

    useEffect(() => {
        const handleGoogleCallback = async () => {
            const params = new URLSearchParams(window.location.search);
            const code = params.get('code');

            if (code) {
                try {
                    await axios.post('/auth/google/callback', { code });

                    await login();
                } catch (error) {
                    handleError({
                        error,
                        message: "Error during Google authentication callback",
                        filename,
                        functionName: "handleGoogleCallback",
                    });
                } finally {
                    navigate('/');
                }
            } else {
                handleError({
                    error: "Error during Google authentication callback. Code not retrieved.",
                    message: "Error during Google authentication callback. Code not retrieved.",
                    filename,
                    functionName: "handleGoogleCallback",
                });
                navigate('/');
            }
        };

        handleGoogleCallback();
    }, [login, navigate]);

    return <LoadingScreen />;
};

export default AuthGoogleCallback;
