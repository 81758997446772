// ----------------------------------------------------------------------

export default function Skeleton(theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.neutral,
          '&::after': {
            background: `linear-gradient(90deg, rgba(226,208,146,0) 0%, rgba(226,208,146,0.5) 50%, rgba(226,208,146,0) 100%)`,
          },
        },
      },
    },
  };
}
