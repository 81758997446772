export const sortByCreatedAtAscending = (array) => {
    return array.sort((itemA, itemB) => {
        return new Date(itemA.created_at).getTime() - new Date(itemB.created_at).getTime() || -1;
    })
}

export const sortByUpdatedAtAscending = (array) => {
    return array.sort((itemA, itemB) => {
        return new Date(itemA.updated_at).getTime() - new Date(itemB.updated_at).getTime() || -1;
    })
}

export const sortByCreatedAtDescending = (array) => {
    return array.sort((itemA, itemB) => {
        return new Date(itemB.created_at).getTime() - new Date(itemA.created_at).getTime() || -1;
    })
}

export const sortByUpdatedAtDescending = (array) => {
    return array.sort((itemA, itemB) => {
        return new Date(itemB.updated_at).getTime() - new Date(itemA.updated_at).getTime() || -1;
    })
}

export const sortQuestionsByScoreAscending = (array) => {
    return array.sort((itemA, itemB) => itemA.score - itemB.score);
}

export const sortQuestionsByScoreDescending = (array) => {
    return array.sort((itemA, itemB) => itemB.score - itemA.score);
}