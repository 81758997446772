import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, Button } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import { HEADER, NAV } from '../../../config-global';
import Logo from '../../../components/logo';
import { useStoresContext } from '../../../stores/useStoresContext';
import { NEW_USER, RETURNING_USER } from '../../../constants/unauthenticated-user-reasons';
import { CREATING_ACCOUNT_DEFAULT, SIGNING_IN_DEFAULT } from '../../../constants/analytics-events';
import { handleEvent } from '../../../utils/handleEvent';

Header.propTypes = {
  isNavHorizontal: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  sx: PropTypes.object,
  openNav: PropTypes.bool,  // Add this line
};

export default function Header({ isNavHorizontal, isAuthenticated, sx, openNav }) {  // Add openNav here
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const { layoutStore } = useStoresContext();

  const handleAuthModalOpen = () => {
    layoutStore.openAuthModal({ flowType: "auth", isUserNew: RETURNING_USER });

    handleEvent({
      eventName: SIGNING_IN_DEFAULT,
      isDesktop: isDesktop,
    });
  };

  const handleRegisterModalOpen = () => {
    layoutStore.openAuthModal({ flowType: "auth", isUserNew: NEW_USER });

    handleEvent({
      eventName: CREATING_ACCOUNT_DEFAULT,
      isDesktop: isDesktop,
    });
  };

  const renderContent = (
    <>
      {isNavHorizontal && <Logo sx={{ mr: 2.5 }} noText={true} />}
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 1, sm: 1.5 }}
      >
        {isAuthenticated ? (
          null
        ) : (
          <>
            <Button
              variant="outlined"
              size={isDesktop ? "medium" : "small"}
              onClick={handleAuthModalOpen}
            >
              Sign In
            </Button>
            <Button
              variant="contained"
              size={isDesktop ? "medium" : "small"}
              onClick={handleRegisterModalOpen}>
              Create Account
            </Button>
          </>
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height', 'width'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${openNav ? NAV.W_DASHBOARD : NAV.W_DASHBOARD_MINI + 1}px)`,  // Adjust width based on openNav
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
        }),
        ...sx,
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
