import { makeObservable, observable, action } from "mobx";

class SwipeStore {
    teaseDirection = "right";

    constructor() {
        makeObservable(this, {
            teaseDirection: observable,
            setTeaseDirection: action,
            reset: action,
        });
    }

    setTeaseDirection(direction) {
        this.teaseDirection = direction;
    }

    reset() {
        this.teaseDirection = "right";
    }
}

const swipeStore = new SwipeStore();

export default swipeStore;
