import React, { useContext } from 'react';
import { AuthEntryContext, AuthEntryProvider } from './AuthEntryContext';
import { AUTH_ENTRY_STEP } from './constants';
import { PhoneNumberForm, OTPVerificationForm } from './forms';
import { styled } from '@mui/material/styles';

// ---------------------------------------------------------------------

const StepsToFormMapper = () => {
  const { state } = useContext(AuthEntryContext);

  switch (state.step) {
    case AUTH_ENTRY_STEP.PHONE_NUMBER_FORM:
      return <PhoneNumberForm />;
    case AUTH_ENTRY_STEP.OTP_VERIFICATION_FORM:
      return <OTPVerificationForm />;
    default:
      return null;
  }
}

export default function AuthEntry() {
  return (
    <AuthEntryProvider>
      <StepsToFormMapper />
    </AuthEntryProvider>
  );
}
