import PropTypes from 'prop-types';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthEntryPage } from '../routes/elements';
// routes
import { PATH_AUTH, PATH_PAGE } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen/LoadingScreen';
// context
import { useAuthContext } from './useAuthContext';
import { useState } from 'react';

// ----------------------------------------------------------------------

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default function AdminGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuthContext();
  const [loadingComplete, setLoadingComplete] = useState(false);

  
  const navigate = useNavigate();
  
  const handleLoadingComplete = () => {
    setLoadingComplete(true);
  };

  if (!isInitialized || !loadingComplete) {
    return <LoadingScreen onLoadingComplete={handleLoadingComplete} />;
  }

  if (!isAuthenticated) {
    navigate(PATH_AUTH.root, { replace: true });

    return;
  }

  const { is_admin } = user;

  if (is_admin) {
    return <> {children} </>;
  }

  return <Navigate to={PATH_PAGE.page403} />;
}
