import PropTypes from "prop-types";
import { observer } from 'mobx-react';
import { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { pxToRem } from "../../../theme/typography";
import Iconify from "../../../components/iconify";
import useResponsive from "../../../hooks/useResponsive";
import useNewQuestionCountdown from "../../../hooks/useNewQuestionCountdown";
import BaseModal from "../../../components/base-modal/BaseModal";

// Styled components for the mobile pill-like countdown
const CountdownPill = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.lightNeutral,
    color: "#069383",
    border: `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`,
    padding: pxToRem(6),
    borderRadius: theme.shape.borderRadius,
    width: "max-content",
    justifyContent: "center",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: pxToRem(6),
}));

const NewQuestionCountdown = observer(() => {
    const theme = useTheme();
    const isDesktop = useResponsive("up", "lg");

    const { timeRemainingFormatted, newQuestionIsUnseen, selectActiveQuestion } = useNewQuestionCountdown();

    const [open, setOpen] = useState(false); // Modal open state

    // Function to handle opening the modal for mobile
    const handleOpenModal = () => {
        if (!isDesktop) {
            setOpen(true);
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: isDesktop ? "flex-start" : "center",
                    alignItems: "center",
                    cursor: "pointer", // Make the pill clickable for both mobile and desktop
                }}
                data-qa-id={"new-question-countdown-container"}
                onClick={newQuestionIsUnseen ? selectActiveQuestion : handleOpenModal} // Handle desktop/new question vs. mobile modal
            >
                {/* Icon and text structure remain the same, we just change the content */}
                {isDesktop ? (
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: "bold",
                            color: theme.palette.secondary.contrastText,
                            display: "flex",
                            alignItems: "center",
                            textDecoration: newQuestionIsUnseen ? 'underline' : 'none',
                            cursor: newQuestionIsUnseen ? 'pointer' : 'text',
                        }}
                        data-qa-id={newQuestionIsUnseen ? "see-new-question-text" : "time-remaining-text"}
                    >
                        <Iconify
                            icon={newQuestionIsUnseen ? "fa-solid:glass-cheers" : "bxs:hourglass"}
                            sx={{
                                width: pxToRem(16),
                                height: pxToRem(16),
                                marginRight: pxToRem(4),
                                color: "inherit",
                            }}
                        />
                        {newQuestionIsUnseen ? "New question" : (timeRemainingFormatted || "calculating")}
                    </Typography>
                ) : (
                    <CountdownPill data-qa-id={newQuestionIsUnseen ? "mobile-see-new-question-pill" : "mobile-countdown-pill"}>
                        <IconWrapper>
                            <Iconify
                                icon={newQuestionIsUnseen ? "fa-solid:glass-cheers" : "bxs:hourglass"}
                                sx={{ width: pxToRem(16), height: pxToRem(16), color: theme.palette.secondary.contrastText }}
                            />
                        </IconWrapper>
                        <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                        >
                            {newQuestionIsUnseen ? "New question" : (timeRemainingFormatted || "calculating")}
                        </Typography>
                    </CountdownPill>
                )}
            </Box>

            {/* Modal that shows when pill is clicked in mobile */}
            <BaseModal
                open={open}
                onClose={handleCloseModal}
                title={`${timeRemainingFormatted} until next question`} // Show the actual time remaining
                subheaderText="COUNTDOWN"
                description="This is how long until the next daily question will appear."
            />
        </>
    );
});

NewQuestionCountdown.propTypes = {};

export default NewQuestionCountdown;
