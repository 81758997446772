import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { PATH_AUTH } from '../routes/paths';
import axios from '../utils/axios';
import cookies from '../utils/cookies';
import { attemptRefresh } from './utils';
import { API_URL } from '../config-global';
import { handleError } from "../utils/handleError";
import posthog from 'posthog-js';

// ----------------------------------------------------------------------

const filename = "src/auth/JwtContext.js";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'REFRESH_USER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const checkUserAccess = async ({ onSuccess, onFailure }) => {
    const hasAccessToken = cookies.get('authSession');

    if (hasAccessToken) {
      if (onSuccess) {
        await onSuccess();
      }
      return true;
    }
    if (onFailure) {
      await onFailure();
    }
    return false;
  };

  const onInitialDispatchSuccess = useCallback(async () => {
    const response = await axios.get('/auth/me');
    const { user } = response.data;
    dispatch({
      type: 'INITIAL',
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  }, []);

  const initialize = useCallback(async () => {
    try {
      console.log("Initialize APP JWT Context")
      let userHasAccess = await checkUserAccess({ onSuccess: onInitialDispatchSuccess });

      if (!userHasAccess) {
        const hasRefreshToken = cookies.get('refreshTokenID');

        if (!hasRefreshToken) {
          return dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }

        const { success } = await attemptRefresh();

        if (success) {
          await checkUserAccess({
            onSuccess: onInitialDispatchSuccess,
            onFailure: () => {
              dispatch({
                type: 'INITIAL',
                payload: {
                  isAuthenticated: false,
                  user: null,
                },
              });
            },
          });
        } else {
          return dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }
    } catch (error) {
      handleError({
        error,
        message: "Error initializing app",
        filename,
        functionName: "initialize",
      });

      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const sendOTP = useCallback(async (phone) => {
    const response = await axios.post('/auth/sendOTP', { phone });
    return response.data;
  }, []);

  const verifyOTP = useCallback(async ({ phone, hash, otp }) => {
    const response = await axios.post('/auth/verifyOTP', { phone, hash, otp });
    return response.data;
  }, []);

  const login = useCallback(async () => {
    await checkUserAccess({
      onSuccess: async () => {
        const response = await axios.get('/auth/me');

        const { user } = response.data;

        dispatch({
          type: 'LOGIN',
          payload: {
            user,
          },
        });

        posthog.identify(user.user_id, {
          user_id: user.user_id,
          name: user.first_name,
          environment: process.env.REACT_APP_VERCEL_ENV,
        });
      },
      onFailure: () => {
        console.log("failed login")
        window.location.href = PATH_AUTH.login
      },
    });
  }, []);

  const register = useCallback(async ({ phone, first_name }) => {
    const response = await axios.post('/auth/register', {
      phone,
      first_name,
    });

    const { user } = response.data;

    dispatch({
      type: 'REGISTER',
      payload: {
        user: null,
      },
    });

    posthog.identify(user.user_id, {
      user_id: user.user_id,
      name: user.first_name,
      environment: process.env.REACT_APP_VERCEL_ENV,
    });
  }, []);

  const getDomain = () => {
    if (process.env.REACT_APP_VERCEL_ENV === "production") {
      return process.env.FRONTEND_DOMAIN_PRODUCTION;
    } else if (process.env.REACT_APP_VERCEL_ENV === "preview") {
      return process.env.FRONTEND_DOMAIN_PREVIEW;
    }
    return "localhost";
  };

  const logout = useCallback(async () => {
    await axios.get('/auth/logout');

    // For non-httpOnly cookies
    cookies.remove('authSession', { path: '/', domain: getDomain() });
    cookies.remove('refreshTokenID', { path: '/', domain: getDomain() });

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const refreshUser = useCallback(async () => {
    const response = await axios.get('/auth/me');
    const { user } = response.data;
    dispatch({
      type: 'REFRESH_USER',
      payload: {
        user,
      },
    });
  }, []);

  const loginWithGoogle = useCallback(() => {
    window.location.href = `${API_URL}/auth/google`;
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      sendOTP,
      verifyOTP,
      refreshUser,
      loginWithGoogle,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, sendOTP, verifyOTP, refreshUser, loginWithGoogle]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
