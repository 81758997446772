import { useEffect, useState } from 'react';
import { useStoresContext } from '../stores/useStoresContext';
import { timeTo } from '../utils/date';
import useResponsive from './useResponsive';

const CHECK_INTERVAL = 1000; // 1-second interval

const useNewQuestionCountdown = () => {
    const { questionStore } = useStoresContext();
    const [timeRemainingFormatted, setTimeRemainingFormatted] = useState('');
    const [newQuestionIsUnseen, setNewQuestionIsUnseen] = useState(false);

    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        // Set interval to check countdown and unseen question
        const checkIntervalId = setInterval(() => {
            const activeQuestion = questionStore.activeQuestion;
            if (!activeQuestion) return;

            const remainingTimeObj = timeTo(activeQuestion.expiration_date, isDesktop);

            if (remainingTimeObj.value > 1000) {
                // Update the formatted time remaining
                if (isDesktop) {
                    setTimeRemainingFormatted(`${remainingTimeObj.formatted} until next question`);
                } else {
                    setTimeRemainingFormatted(remainingTimeObj.formatted);
                }
            } else {
                // If time is up, refresh questions after a small delay
                setTimeRemainingFormatted("calculating");
                setTimeout(() => {
                    questionStore.hardRefresh(); // Call refresh when countdown ends
                }, 2000);
            }

            // Check if the active question is unseen and update state
            setNewQuestionIsUnseen(questionStore.newQuestionIsUnseen);
        }, CHECK_INTERVAL);

        // Clean up the interval on unmount
        return () => clearInterval(checkIntervalId);
    }, [questionStore]);

    const selectActiveQuestion = () => {
        questionStore.changeSelectedQuestion(questionStore.activeQuestion);
    };

    return {
        timeRemainingFormatted,
        newQuestionIsUnseen,
        selectActiveQuestion,
    };
};

export default useNewQuestionCountdown;
