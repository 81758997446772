// @mui
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledRoot = styled('main')(({ theme, isDesktop }) => ({
  padding: isDesktop ? theme.spacing(6, 6, 0, 6) : theme.spacing(4, 4, 0, 4),
}));

export const StyledContent = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));
