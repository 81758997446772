import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Button, Typography, Box, styled } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import { AuthEntryContext } from "../../AuthEntryContext";
import FormProvider from "../../../../../components/hook-form";
import { StyledRoot, StyledContent } from "./styles";
import useResponsive from "../../../../../hooks/useResponsive";
import { useAuthContext } from "../../../../../auth/useAuthContext";
import { pxToRem } from "../../../../../theme/typography";
import Iconify from "../../../../../components/iconify/Iconify";
import { useStoresContext } from "../../../../../stores/useStoresContext";
import { CustomHeader } from "../../../../../components/custom-header";
import { NEW_USER, USER_UNSURE, RETURNING_USER } from "../../../../../constants/unauthenticated-user-reasons";
import { SIGNING_IN_BY_ANSWERING, CREATING_ACCOUNT_BY_ANSWERING } from "../../../../../constants/analytics-events";

import { handleError } from "../../../../../utils/handleError";
import { handleEvent } from "../../../../../utils/handleEvent";
import SvgColor from "../../../../../components/svg-color";
import CharacterAnimation from "../../../../../animation/CharacterAnimation";
import { MobileHeader } from "../../../../../layouts/app/header";
import MobileBottomBar from "../../../../../layouts/app/bottom/MobileBottomBar";
import gumDropFireWaving from "../../../../../animation-data/gumdrop_fire_waving.json";
import pageFlip from "../../../../../animation-data/page_flip.json";
import { HEADER } from "../../../../../config-global";

const filename = "/sections/auth/auth-entry/forms/phone-number-form/PhoneNumberForm.js";

const SPACING = 8;

function PhoneNumberForm() {
    const { state, updateFields } = useContext(AuthEntryContext);
    const { sendOTP, loginWithGoogle } = useAuthContext();
    const { layoutStore } = useStoresContext();

    const isDesktop = useResponsive("up", "lg");
    const isTallScreen = useResponsive('up', '700px', undefined, 'height'); // Check if height is greater than 700px
    const [showPhoneInput, setShowPhoneInput] = useState(false);

    const PhoneSchema = Yup.object().shape({
        phone: Yup.string()
            .test("is-valid-phone", "Must be a valid phone number", value => isValidPhoneNumber(value))
            .required("Phone number is required"),
    });

    const defaultValues = {
        phone: state.phone ?? "",
    };

    const methods = useForm({
        resolver: yupResolver(PhoneSchema),
        defaultValues,
        mode: "onChange",
    });

    const {
        reset,
        handleSubmit,
        setValue,
        formState: { isValid, errors },
    } = methods;

    useEffect(() => {
        // if phone exists, then open phone with phone number editable
        if (state.phone) {
            setShowPhoneInput(true)
        }
    }, []);

    const onSubmit = async (data) => {
        try {
            const { hash } = await sendOTP(data.phone);

            if (hash) {
                updateFields({ phone: data.phone, hash });
            }
        } catch (error) {
            handleError({
                error: error,
                message: `Error submitting with phone`,
                filename,
                functionName: "onSubmit",
            });

            reset();
        }
    };

    const handlePhoneChange = (value) => {
        const phoneValue = value.startsWith("+") ? value : `+${value}`;
        setValue("phone", phoneValue, { shouldValidate: true });
    };

    const handleClose = () => {
        layoutStore.closeAuthModal();
    };

    const getCustomHeaderTitleNode = (userType) => {
        if (showPhoneInput) {
            return (
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                    <Typography variant={isDesktop ? "caption" : "body1"} sx={{ textAlign: "left", margin: `${pxToRem(12)} 0` }}>
                        {layoutStore.isUserNew === NEW_USER ? "CREATE ACCOUNT" : "SIGN IN"}
                    </Typography>
                    <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end" }}>
                        Enter your phone number
                    </Typography>
                </Box>
            )
        }

        switch (userType) {
            case NEW_USER:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "caption" : "body1"} sx={{ textAlign: "left", margin: `${pxToRem(12)} 0` }}>
                            {"CREATE ACCOUNT"}
                        </Typography>
                        <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end" }}>
                            Welcome to Orchard Journal
                        </Typography>
                    </Box>
                );
            case RETURNING_USER:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "caption" : "body1"} sx={{ textAlign: "left", margin: `${pxToRem(12)} 0` }}>
                            {"SIGN IN"}
                        </Typography>
                        <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end" }}>
                            Welcome back
                        </Typography>
                    </Box>
                );
            case USER_UNSURE:
                // happens when user answers question without being logged in
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "caption" : "body1"} sx={{ textAlign: "left", margin: `${pxToRem(12)} 0` }}>
                            {"ACCOUNT"}
                        </Typography>
                        <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end" }}>
                            Keep the flame burning
                        </Typography>
                    </Box>
                );
            default:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "caption" : "body1"} sx={{ textAlign: "left", margin: `${pxToRem(12)} 0` }}>
                            {"CREATE ACCOUNT"}
                        </Typography>
                        <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end" }}>
                            Welcome to Orchard Journal
                        </Typography>
                    </Box>
                );
        }
    };

    const getBodyContent = (userType) => {
        console.log({ isTallScreen })
        switch (userType) {
            case NEW_USER:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="body1">
                            A daily journal designed to promote self-reflection and growth awaits you. Create an account to keep the page turning.
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: pxToRem(16) }}>
                            <CharacterAnimation
                                animationData={pageFlip}
                                style={{
                                    height: isDesktop ? pxToRem(180) : pxToRem(180),
                                    width: isDesktop ? pxToRem(180) : pxToRem(180),
                                }}
                            />
                        </Box>
                    </Box>
                );
            case RETURNING_USER:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="body1" sx={{ width: "100%" }}>
                            We're excited to see you again. Sign in to get back to journaling.
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: pxToRem(16) }}>
                            <CharacterAnimation
                                animationData={pageFlip}
                                style={{
                                    height: isDesktop ? pxToRem(180) : pxToRem(180),
                                    width: isDesktop ? pxToRem(180) : pxToRem(180),
                                }}
                            />
                        </Box>
                    </Box>
                );
            case USER_UNSURE:
                // happens when user answers question without being logged in
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="body1">
                            Our daily journal aims to help those with OCD build a stronger relationship with themselves. Create an account or sign in to keep your streak.
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: pxToRem(16) }}>
                            <CharacterAnimation
                                animationData={gumDropFireWaving}
                                style={{
                                    height: isDesktop ? pxToRem(180) : (isTallScreen ? pxToRem(180) : pxToRem(100)),
                                    width: isDesktop ? pxToRem(180) : (isTallScreen ? pxToRem(180) : pxToRem(120)),
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: pxToRem(16) }} data-qa-id={"streaks-container"}>
                            <StreakButton sx={{ marginRight: pxToRem(8) }} isDesktop={isDesktop}>
                                <IconWrapper isDesktop={isDesktop}>
                                    <SvgColor src={`/assets/icons/streak/fire.svg`} sx={{ width: pxToRem(16), height: pxToRem(16), color: "#FFFFFF" }} data-qa-id={"streak-icon"} />
                                </IconWrapper>
                                <AnimatedTypography>
                                    Day 1
                                </AnimatedTypography>
                            </StreakButton>

                            <StreakButton sx={{ marginRight: pxToRem(8) }} isDesktop={isDesktop} disabled={true}>
                                <IconWrapper isDesktop={isDesktop} disabled={true}>
                                    <SvgColor src={`/assets/icons/streak/fire.svg`} sx={{ width: pxToRem(16), height: pxToRem(16), color: "#FFFFFF" }} data-qa-id={"streak-icon"} />
                                </IconWrapper>
                                <AnimatedTypography>
                                    Day 2
                                </AnimatedTypography>
                            </StreakButton>

                            <StreakButton isDesktop={isDesktop} disabled={true}>
                                <IconWrapper isDesktop={isDesktop} disabled={true}>
                                    <SvgColor src={`/assets/icons/streak/fire.svg`} sx={{ width: pxToRem(16), height: pxToRem(16), color: "#FFFFFF" }} data-qa-id={"streak-icon"} />
                                </IconWrapper>
                                <AnimatedTypography>
                                    Day 3
                                </AnimatedTypography>
                            </StreakButton>
                        </Box>
                    </Box>
                );
            default:
                return (
                    <Typography variant="body1">
                        A daily journal designed to promote self-reflection and growth awaits you. Create an account to keep the page turning.
                    </Typography>
                );
        }
    };

    const customHeaderTitleNode = getCustomHeaderTitleNode(layoutStore.isUserNew);
    const bodyContent = getBodyContent(layoutStore.isUserNew);

    const renderButtons = () => {
        if (layoutStore.isUserNew === USER_UNSURE) {
            // case of user answering a question and onboarding/signing in from there
            if (isDesktop) {
                return (
                    <Stack direction="row" justifyContent="center" spacing={2} sx={{ width: 1, margin: "auto" }}>
                        <Button
                            fullWidth
                            size="medium"
                            variant="contained"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                layoutStore.setIsUserNew(RETURNING_USER);

                                handleEvent({
                                    eventName: SIGNING_IN_BY_ANSWERING,
                                    isDesktop: isDesktop,
                                });
                            }}
                        >
                            Sign In
                        </Button>
                        <Button
                            fullWidth
                            size="medium"
                            variant="contained"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                layoutStore.setIsUserNew(NEW_USER);

                                handleEvent({
                                    eventName: CREATING_ACCOUNT_BY_ANSWERING,
                                    isDesktop: isDesktop,
                                });
                            }}
                        >
                            Create New Account
                        </Button>
                    </Stack>
                );
            }

            return (
                <Stack direction="column" justifyContent="center" spacing={2} sx={{ width: 1, margin: "auto" }}>
                    <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "none",
                        }}
                        onClick={() => {
                            layoutStore.setIsUserNew(NEW_USER);

                            handleEvent({
                                eventName: CREATING_ACCOUNT_BY_ANSWERING,
                                isDesktop: isDesktop,
                            });
                        }}
                    >
                        Get started
                    </Button>
                    <Button
                        fullWidth
                        size="medium"
                        variant="outlined"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "none",
                        }}
                        onClick={() => {
                            layoutStore.setIsUserNew(RETURNING_USER);

                            handleEvent({
                                eventName: SIGNING_IN_BY_ANSWERING,
                                isDesktop: isDesktop,
                            });
                        }}
                    >
                        I already have an account
                    </Button>
                </Stack>
            );
        }

        if (isDesktop) {
            return (
                <Stack direction="row" justifyContent="center" spacing={2} sx={{ width: 1, margin: "auto" }}>
                    <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => setShowPhoneInput(true)}
                    >
                        <Iconify icon="mdi:phone" sx={{ mr: 1 }} />
                        Continue with Phone
                    </Button>

                    <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={loginWithGoogle}
                    >
                        <Iconify icon="mdi:gmail" sx={{ mr: 1 }} />
                        Continue with Google
                    </Button>
                </Stack>
            );
        }

        return (
            <Stack direction="column" justifyContent="center" spacing={2} sx={{ width: 1, margin: "auto" }}>
                <Button
                    fullWidth
                    size="medium"
                    variant="outlined"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={() => setShowPhoneInput(true)}
                >
                    <Iconify icon="mdi:phone" sx={{ mr: 1 }} />
                    Continue with Phone
                </Button>

                <Button
                    fullWidth
                    size="medium"
                    variant="outlined"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={loginWithGoogle}
                >
                    <Iconify icon="mdi:gmail" sx={{ mr: 1 }} />
                    Continue with Google
                </Button>
            </Stack>
        );
    };

    return (
        <StyledRoot isDesktop={isDesktop} data-qa-id="phone-number-root-container">
            {isDesktop ? (
                <CustomHeader
                    onClose={handleClose}
                    closeIconKey="mingcute:close-fill"
                    titleNode={customHeaderTitleNode}
                />

            ) : (
                <MobileHeader
                    onClose={handleClose}
                    closeIconKey="mingcute:close-fill"
                />
            )}
            <StyledContent isDesktop={isDesktop} data-qa-id="phone-number-content-container">
                <Stack spacing={isDesktop ? 4 : 2} sx={{ mt: isDesktop ? 2 : 0, mb: 4, width: "100%", paddingTop: isDesktop ? "0" : pxToRem(HEADER.H_MOBILE + SPACING), }} data-qa-id="phone-number-stack-container">
                    {!isDesktop && customHeaderTitleNode}

                    {!showPhoneInput && bodyContent}

                    {showPhoneInput ? (
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Stack
                                direction={"column"}
                                justifyContent={"center"}
                                spacing={2}
                                sx={{ width: 1, margin: isDesktop ? "auto" : "inherit" }}
                                data-qa-id="phone-number-form-container"
                            >
                                <Controller
                                    name="phone"
                                    control={methods.control}
                                    render={({ field }) => (
                                        <>
                                            <PhoneInput
                                                {...field}
                                                country={"us"}
                                                onChange={(value, country) => handlePhoneChange(value, country)}
                                                inputStyle={{
                                                    width: "100%",
                                                    height: "56px",
                                                    fontSize: "16px",
                                                    paddingLeft: "48px",
                                                    borderRadius: "4px",
                                                    border: "1px solid #ccc",
                                                }}
                                                inputProps={{
                                                    name: "phone",
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                            />
                                            {errors.phone && (
                                                <Typography variant="body2" color="error">
                                                    {errors.phone.message}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                />
                                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                    We'll send you a text with a verification code. Message and data rates may apply.
                                </Typography>

                                {isDesktop ? (
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={!isValid}
                                        data-qa-id="phone-number-next-button"
                                    >
                                        Next
                                    </Button>
                                ) : (
                                    <MobileBottomBar>
                                        <Typography
                                            component="div"
                                            sx={{ color: "text.primary", mb: 2, typography: "caption", textAlign: "left" }}
                                            data-qa-id="phone-number-terms-privacy-container"
                                        >
                                            {"I confirm I am at least 13 years old and meet my country's minimum age requirement to use these services."}
                                        </Typography>

                                        <Button
                                            fullWidth
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            disabled={!isValid}
                                            sx={{
                                                textTransform: "none",
                                            }}
                                            data-qa-id="phone-number-next-button"
                                        >
                                            Send code
                                        </Button>
                                    </MobileBottomBar>
                                )}
                            </Stack>
                        </FormProvider>
                    ) : (
                        <>
                            {isDesktop ? (
                                renderButtons()
                            ) : (
                                <MobileBottomBar>
                                    <Typography
                                        component="div"
                                        sx={{ color: "text.primary", mb: 2, typography: "caption", textAlign: "left" }}
                                        data-qa-id="phone-number-terms-privacy-container"
                                    >
                                        {"I confirm I am at least 13 years old and meet my country's minimum age requirement to use these services."}
                                    </Typography>

                                    {renderButtons()}
                                </MobileBottomBar>
                            )}
                        </>
                    )}
                </Stack>
            </StyledContent>
        </StyledRoot>
    );
}

const StreakButton = styled(Button)(({ theme, disabled = false }) => ({
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    minWidth: "fit-content",
    backgroundColor: theme.palette.background.lightNeutral,
    color: "#069383",
    border: `${pxToRem(2)} solid ${disabled ? "#AEA7A7" : theme.palette.card.borderStroke}`,
    padding: pxToRem(6),
    "&:hover": {
        backgroundColor: theme.palette.button.hover.outlinedBackground,

    },
}));

const IconWrapper = styled(Box)(({ theme, isDesktop, disabled = false }) => ({
    backgroundColor: disabled ? "#AEA7A7" : "#FC8800",
    borderRadius: isDesktop ? theme.shape.borderRadius : theme.shape.borderRadius / 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: pxToRem(10),
    padding: theme.spacing(isDesktop ? 1 : 0.5),
}));

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: pxToRem(16),
    color: theme.palette.secondary.contrastText,
}));

export default observer(PhoneNumberForm);