import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useAuthContext } from "../../auth/useAuthContext";
import { Button, Typography, Box, styled } from "@mui/material";
import { pxToRem } from "../../theme/typography";
import SvgColor from "../svg-color";
import useResponsive from "../../hooks/useResponsive";
import { fadeIn } from "./styles";
import getStreakSummaryContent from "../../utils/streakSummaryContent";
import BaseModal from "../base-modal/BaseModal";
import { useStoresContext } from "../../stores/useStoresContext";
import { GUMDROP_FIRE_WAVING } from "../../constants/character-animation-events";
import CharacterAnimation from "../../animation/CharacterAnimation";

// Styled components
const StreakButton = styled(Button)(({ theme, isDayZero = false }) => ({
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    minWidth: "fit-content",
    backgroundColor: theme.palette.background.lightNeutral,
    color: "#069383",
    border: `${pxToRem(2)} solid ${isDayZero ? "#AEA7A7" : theme.palette.card.borderStroke}`,
    padding: pxToRem(6),
    "&:hover": {
        backgroundColor: theme.palette.button.hover.outlinedBackground,

    },
}));

const IconWrapper = styled(Box)(({ theme, isDesktop, isDayZero = false }) => ({
    backgroundColor: isDayZero ? "#AEA7A7" : "#FC8800",
    borderRadius: isDesktop ? theme.shape.borderRadius : theme.shape.borderRadius / 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: pxToRem(10),
    padding: theme.spacing(isDesktop ? 1 : 0.5),
}));

const AnimatedTypography = styled(Typography)(({ theme, condensed }) => ({
    fontWeight: 600,
    fontSize: pxToRem(16),
    animation: `${condensed ? fadeIn : "none"} 0.5s ease-in-out`,
    color: theme.palette.secondary.contrastText,
}));

const BottomSection = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "rgba(255, 150, 1, 0.5)",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: "100%",
}));

const StatBox = styled(Box)(({ theme }) => ({
    textAlign: "center",
    "& .MuiTypography-h4": {
        color: theme.palette.text.primary, // Use the same text color as the rest of the dialog
    },
    "& .MuiTypography-body2": {
        color: theme.palette.text.secondary,
    },
}));

const VerticalDivider = styled(Box)(({ theme }) => ({
    width: pxToRem(2),
    height: pxToRem(30),
    backgroundColor: "#FF9601",
}));

const StreakDialog = observer(({ open, handleClose, user }) => {
    const currentStreak = user?.userStreak?.current_streak || 0;
    const questionAnswerCount = user?.questionSubscriptionCount || 0;

    const descriptionText = getStreakSummaryContent(currentStreak);

    const { isReady: storesAreReady, characterAnimationStore } = useStoresContext();

    useEffect(() => {
        const initialize = async () => {
            if (storesAreReady) {
                characterAnimationStore.updateAnimation(GUMDROP_FIRE_WAVING)
            }
        };

        initialize();

        // reset on dialog unmount
        return () => {
            characterAnimationStore.reset();
        }

    }, [characterAnimationStore, storesAreReady]);

    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            title="Streak summary"
            subheaderText="STREAK"
            description={descriptionText}
        >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CharacterAnimation
                    animationData={characterAnimationStore.currentAnimationData}
                />
            </Box>
            <BottomSection data-qa-id={"bottom-section"}>
                <StatBox>
                    <Typography variant="h4">{currentStreak}</Typography>
                    <Typography variant="body2">Current Streak</Typography>
                </StatBox>
                <VerticalDivider data-qa-id={"vertical-divider"} />
                <StatBox>
                    <Typography variant="h4">{questionAnswerCount}</Typography>
                    <Typography variant="body2">Total Entries</Typography>
                </StatBox>
            </BottomSection>
            <Typography variant="caption" sx={{ marginTop: `${pxToRem(16)} !important`, fontWeight: 600 }} data-qa-id="streak-instructional-info">
                Your streak is built on consecutive days of answering the daily question. Only "Today" answers count toward your streak. Past answers add to your total entries but not your streak.
            </Typography>
        </BaseModal>
    );
});

const Streak = observer(() => {
    const { user } = useAuthContext();

    const currentStreak = user?.userStreak?.current_streak || 0;

    const isDesktop = useResponsive("up", "lg");

    const [condensed, setCondensed] = useState(!isDesktop);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isDesktop) {
            const timer = setTimeout(() => {
                setCondensed(true);
            }, 15000);

            return () => clearTimeout(timer);
        } else {
            setCondensed(true);
        }
    }, [isDesktop]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <StreakButton
                onClick={handleClickOpen}
                isDesktop={isDesktop}
                isDayZero={currentStreak === 0}
            >
                <IconWrapper isDesktop={isDesktop} isDayZero={currentStreak === 0}>
                    <SvgColor src={`/assets/icons/streak/fire.svg`} sx={{ width: pxToRem(12), height: pxToRem(16), color: "#FFFFFF" }} data-qa-id={"streak-icon"} />
                </IconWrapper>
                <AnimatedTypography condensed={condensed}>
                    {condensed ? currentStreak : `Day ${currentStreak}`}
                </AnimatedTypography>
            </StreakButton>
            <StreakDialog open={open} handleClose={handleClose} user={user} isDesktop={isDesktop} />
        </>
    );
});

export default Streak;
