
import { questionStore } from "./question-store";
import { matchStore } from "./match-store";
import { layoutStore } from "./layout-store";
import { characterAnimationStore } from "./character-animation-store";
import { swipeStore } from "./swipe-store";

const stores = {
    questionStore,
    matchStore: matchStore,
    layoutStore: layoutStore,
    characterAnimationStore: characterAnimationStore,
    swipeStore: swipeStore,
};

export default stores;
