import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const StyledLogoBox = styled(Box)(({ }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transition: 'justify-content 0.3s ease',
}));

export const StyledLogoText = styled(Typography)(({ theme, openNav }) => ({
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    fontSize: { xs: 16, lg: 20 },
    lineHeight: '40px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'opacity 0.3s ease, width 0.3s ease',
    opacity: openNav ? 1 : 0,
    width: openNav ? 'auto' : 0,
    visibility: openNav ? 'visible' : 'hidden',
}));
