// routes
import { PATH_APP } from './routes/paths';

// API
// ----------------------------------------------------------------------

// Hostname config
export const API_URL =
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_PRODUCTION
    : process.env.REACT_APP_VERCEL_ENV === "preview"
      ? process.env.REACT_APP_BACKEND_URL_PREVIEW
      : `http://${window.location.hostname}:${process.env.REACT_APP_SERVER_PORT}`;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_APP.home; // as '/home'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
  //
  H_DESKTOP_LOGO_HEADER: 111,

  // 
  H_BOTTOM_NAVIGATION_BAR_MOBILE: 56,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const FEEDBACK = {
  H_DESKTOP: 51,
};
