import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env[`REACT_APP_FIREBASE_API_KEY_${(process.env.REACT_APP_VERCEL_ENV || "development").toUpperCase()}`],
    authDomain: process.env[`REACT_APP_FIREBASE_AUTH_DOMAIN_${(process.env.REACT_APP_VERCEL_ENV || "development").toUpperCase()}`],
    projectId: process.env[`REACT_APP_FIREBASE_PROJECT_ID_${(process.env.REACT_APP_VERCEL_ENV || "development").toUpperCase()}`],
    storageBucket: process.env[`REACT_APP_FIREBASE_STORAGE_BUCKET_${(process.env.REACT_APP_VERCEL_ENV || "development").toUpperCase()}`],
    messagingSenderId: process.env[`REACT_APP_FIREBASE_MESSAGING_SENDER_ID_${(process.env.REACT_APP_VERCEL_ENV || "development").toUpperCase()}`],
    appId: process.env[`REACT_APP_FIREBASE_APP_ID_${(process.env.REACT_APP_VERCEL_ENV || "development").toUpperCase()}`],
    measurementId: process.env[`REACT_APP_FIREBASE_MEASUREMENT_ID_${(process.env.REACT_APP_VERCEL_ENV || "development").toUpperCase()}`]
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);