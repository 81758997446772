import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { DEFAULT_STEP, AUTH_ENTRY_STEP } from './constants';

const defaultState = {
    step: AUTH_ENTRY_STEP.PHONE_NUMBER_FORM,
}

const defaultContext = {
    previousStep: () => null,
    state: defaultState,
    setStep: () => null,
    updateFields: () => null,
}

export const AuthEntryContext = createContext(defaultContext);

export const AuthEntryProvider = ({ children }) => {
    const [state, setState] = useState(defaultState);

    const previousStep = () => {
        if (!state.step) {
            state.step = DEFAULT_STEP;
        }

        let newStep = state.step - 1;

        setState({
            ...state,
            step: newStep > AUTH_ENTRY_STEP.PHONE_NUMBER_FORM ? newStep : AUTH_ENTRY_STEP.PHONE_NUMBER_FORM,
        });
    }

    const updateFields = (updateBody, noStepChange = false) => {
        if (!state.step) {
            state.step = DEFAULT_STEP;
        }

        let newStep = state.step + 1;

        setState({
            ...state,
            ...(updateBody || {}),
            step: !noStepChange ? newStep : state.step,
        });
    }

    const setStep = (step) => {
        setState({
            ...state,
            step,
        });
    }

    return (
        <AuthEntryContext.Provider
            value={{
                state,
                previousStep,
                updateFields,
                setStep,
            }}
        >
            {children}
        </AuthEntryContext.Provider>
    );
};

AuthEntryProvider.propTypes = {
    children: PropTypes.node,
};