// stores/characterAnimationStore.js
import { makeObservable, observable, action, runInAction } from "mobx";
import getAnimationData from "../../utils/getAnimationData";

class CharacterAnimationStore {
  currentAnimationData = null;

  constructor() {
    makeObservable(this, {
      currentAnimationData: observable,
      updateAnimation: action,
      reset: action,
    });

    // Initialize with the idle animation
    this.reset();
  }

  updateAnimation(animationName) {
    if (animationName) {
      const animationDataPath = getAnimationData(animationName);

      runInAction(() => {
        this.currentAnimationData = animationDataPath;
      });
    } else {
      this.currentAnimationData = null;
    }
  }

  reset = () => {
    this.updateAnimation(null);
  }
}

const characterAnimationStore = new CharacterAnimationStore();
export default characterAnimationStore;
