import {
    deleteObject,
} from "firebase/storage";
import { handleError } from "../../utils/handleError";

const filename = "/services/file-service/FileService.js";

export class FileService {

    upload = ({ uploadTaskSnapshot, onProgress, onSuccess, onError }) => {
        // Listen for state changes, errors, and completion of the upload.
        uploadTaskSnapshot.on('state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                onProgress(progress);
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        handleError({
                            error: error,
                            message: `Error uploading code: ${error.code}`,
                            filename,
                            functionName: "upload",
                        });
    
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        handleError({
                            error: error,
                            message: `Error uploading code: ${error.code}`,
                            filename,
                            functionName: "upload",
                        });
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        handleError({
                            error: error,
                            message: `Error uploading code: ${error.code}`,
                            filename,
                            functionName: "upload",
                        });
                        break;
                }

                onError(error);
            },
            async () => {
                // Upload completed successfully, now we can get the download URL
                await onSuccess(uploadTaskSnapshot);
            }
        );
    }

    delete = async ({ ref }) => {
        try {
            await deleteObject(ref);
        } catch (err) {
            handleError({
                error: err,
                message: "Error deleting image",
                filename,
                functionName: "delete",
            });
        }
    }
};

const fileService = new FileService();

export default fileService;