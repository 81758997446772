import Ably from "ably/promises";
import { API_URL } from "../config-global";
import { useEffect } from "react"

// const isNotDevelopment = process.env.REACT_APP_VERCEL_ENV === "production" || process.env.REACT_APP_VERCEL_ENV === "preview";
const isNotDevelopment = true;

export const ably = isNotDevelopment ? new Ably.Realtime.Promise({
    authUrl: `${API_URL}/ably/token-request`,
    logLevel: 1,
}) : null;

export const useChannel = isNotDevelopment ? ({ channelName, eventNames, callbackOnMessageMap, handleUnmount, effectListenerProps = [] }) => {
    const channel = ably.channels.get(channelName);

    const onMount = () => {
        if (eventNames) {
            for (const eventName of eventNames) {
                channel.subscribe(eventName, (msg) => {
                    callbackOnMessageMap[eventName](msg, channel);
                });
            }
        }
    }

    const onUnmount = () => {
        channel.unsubscribe();

        if (handleUnmount) {
            handleUnmount();
        }
    }

    const useEffectHook = () => {
        onMount();
        return () => { onUnmount(); };
    };

    useEffect(useEffectHook, [...effectListenerProps]);

    return [channel, ably];
} : ({ channelName, eventNames, callbackOnMessageMap, handleUnmount, effectListenerProps = [] }) => {
    return [{}, {}];
};