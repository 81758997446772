import { makeAutoObservable } from "mobx";

export default class QuestionSubscription {
    question_subscription_id = null;
    user_id = null;
    question_id = null;
    answer = null;
    created_at = null;
    updated_at = null;

    constructor(json) {
        makeAutoObservable(this);
        if (json) {
            this.updateFromJson(json);
        }
    }

    updateFromJson(json) {
        for (const prop in json) {
            if (prop in json && prop in this) {
                this[prop] = json[prop];
            }
        }
    }
}
