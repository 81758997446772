import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// NEW COLORS

const BURGUNDY = {
  0: "#FBE1A2",
  100: "#FBE1A2",
  200: "#FBE1A2",
  300: "#FFC85E",
  400: "#FFC85E",
  500: "#492C1E",
  600: "#78653E",
  700: "#008181",
  800: "#008181",
  900: "#008181",
};


const BURGUNDY_PRIMARY = {
  lighter: "#008181",
  light: "#008181",
  main: "#36250F",
  dark: "#36250F",
  darker: "#36250F",
  contrastText: "#fff",
};

const BURGUNDY_SECONDARY = {
  lighter: "#FFC85E",
  light: "#FFC85E",
  main: "#FFC85E",
  dark: "#FFC85E",
  darker: "#FFC85E",
  darkerContrastText: "#302403",
  contrastText: "#78653E",
};

const BURGUNDY_INFO = {
  lighter: "#CAFDF5",
  light: "#61F3F3",
  main: "#00B8D9",
  dark: "#006C9C",
  darker: "#003768",
  contrastText: "#fff",
};

const BURGUNDY_SUCCESS = {
  lighter: "#D8FBDE",
  light: "#86E8AB",
  main: "#36B37E",
  dark: "#1B806A",
  darker: "#0A5554",
  contrastText: "#fff",
};

const BURGUNDY_WARNING = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: BURGUNDY[800],
};

const BURGUNDY_ERROR = {
  lighter: "#FFE9D5",
  light: "#FFAC82",
  main: "#FF5630",
  dark: "#B71D18",
  darker: "#7A0916",
  contrastText: "#fff",
};

const BURGUNDY_COMMON = {
  common: { black: "#000", white: "#FFFFFF" },
  primary: BURGUNDY_PRIMARY,
  secondary: BURGUNDY_SECONDARY,
  info: BURGUNDY_INFO,
  success: BURGUNDY_SUCCESS,
  warning: BURGUNDY_WARNING,
  error: BURGUNDY_ERROR,
  grey: BURGUNDY,
  divider: alpha(BURGUNDY[500], 0.24),
  action: {
    hover: alpha(BURGUNDY[700], 0.9),
    outlineHover: alpha(BURGUNDY[700], 0.30),
    selected: alpha(BURGUNDY[500], 0.16),
    disabled: alpha(BURGUNDY[500], 0.8),
    disabledBackground: alpha(BURGUNDY[500], 0.24),
    focus: alpha(BURGUNDY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  stroke: "#5B5750",
  card: {
    borderStroke: "#E5B77E",
  },
  button: {
    containedBorderStroke: "#016161",
    outlinedBorderStroke: "#84734F",
    containedBackground: "#008181",
    outlinedBackground: "#FFFCDD",
    disabled: {
      containedBackground: "#64A78E",
      containedBorderStroke: "#65947B",
      outlinedBackground: "#DCECFE",
      outlinedBorderStroke: "#73A9FB",
    },
    hover: {
      containedBackground: "#016161",
      containedBorderStroke: "#016161",
      outlinedBackground: "#FFE4A9",
      outlinedBorderStroke: "#84734F",
    }
  }
};

///////

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const PRIMARY = {
  lighter: "#C8FACD",
  light: "#5BE584",
  main: "#00AB55",
  dark: "#007B55",
  darker: "#005249",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};

const INFO = {
  lighter: "#CAFDF5",
  light: "#61F3F3",
  main: "#00B8D9",
  dark: "#006C9C",
  darker: "#003768",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#D8FBDE",
  light: "#86E8AB",
  main: "#36B37E",
  dark: "#1B806A",
  darker: "#0A5554",
  contrastText: "#fff",
};

const WARNING = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFE9D5",
  light: "#FFAC82",
  main: "#FF5630",
  dark: "#B71D18",
  darker: "#7A0916",
  contrastText: "#fff",
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  stroke: "#FFFFFF",
};

export default function palette(themeMode) {
  const light = {
    ...BURGUNDY_COMMON,
    mode: "light",
    text: {
      primary: BURGUNDY_PRIMARY.main,
      secondary: BURGUNDY[600],
      disabled: BURGUNDY[300],
    },
    background: { paper: "#FFFBD8", default: "#FFFBD8", lightNeutral: "#FFFCDD", neutral: BURGUNDY[200], darkNeutral: BURGUNDY[400], fadedNeutral: "#FFF6D5" },
    header: { default: "#FFFCDD" },
    action: {
      ...BURGUNDY_COMMON.action,
      active: BURGUNDY[600],
    },
    answerQuestion: {
      successBackground: "#03BBA7"
    }
  };

  // const light = {
  //   ...COMMON,
  //   mode: "light",
  //   text: {
  //     primary: GREY[800],
  //     secondary: GREY[600],
  //     disabled: GREY[500],
  //   },
  //   background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
  //   action: {
  //     ...COMMON.action,
  //     active: GREY[600],
  //   },
  // };

  const dark = {
    ...COMMON,
    mode: "dark",
    text: {
      primary: "#fff",
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return themeMode === "light" ? light : dark;
}
