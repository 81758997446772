import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Box, Typography, styled, keyframes } from '@mui/material';
import { pxToRem } from '../../../theme/typography';
import useResponsive from '../../../hooks/useResponsive';

const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(calc(-100% + 100% / 3));
  }
  60% {
    transform: translateX(calc(-60%));
  }
  100% {
    transform: translateX(0%);
  }
`;

const JournalNameContainer = styled(Box)(({ theme, isDesktop }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    width: "100%",
    justifyContent: isDesktop ? "flex-start" : "center",
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: isDesktop ? 'nowrap' : 'normal',
    position: 'relative',
}));

const JournalNameText = styled(Typography)(({ theme, isScrolling, isDesktop, wordLength }) => ({
    fontWeight: 600,
    whiteSpace: 'nowrap',
    fontSize: isDesktop ? pxToRem(28) : pxToRem(15),
    animation: isScrolling ? `${scroll} ${(10 * wordLength) / 36}s linear` : 'none',
    animationDelay: isScrolling ? '0.5s' : '0s',
    overflow: isDesktop ? (isScrolling ? "visible" : "hidden") : (isScrolling ? "visible" : "overlay"),
    textOverflow: isDesktop && !isScrolling ? "ellipsis" : "clip",
}));

const JournalName = observer(({ journalName, onClick, disabled, customStyles = {} }) => {
    const isDesktop = useResponsive('up', 'lg');
    const textRef = useRef(null);
    const containerRef = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [animationDone, setAnimationDone] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current && containerRef.current) {
                const textWidth = textRef.current.scrollWidth;
                const containerWidth = containerRef.current.clientWidth;

                if (textWidth > containerWidth) {
                    if (!isDesktop) {
                        // Delay animation for mobile by 2 seconds
                        setTimeout(() => {
                            setIsScrolling(true);
                            setAnimationDone(false);
                        }, 2000);
                    } else {
                        setIsScrolling(true);
                        setAnimationDone(false);
                    }
                } else {
                    setIsScrolling(false);
                    setAnimationDone(true);
                }
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [journalName, isDesktop]);

    useEffect(() => {
        if (isScrolling) {
            const timer = setTimeout(() => {
                setIsScrolling(false);
                setAnimationDone(true);
            }, 11000); // 10 seconds for animation + 1 second delay

            return () => clearTimeout(timer);
        }
    }, [isScrolling]);

    const handleMouseEnter = () => {
        if (isDesktop && textRef.current && containerRef.current) {
            const textWidth = textRef.current.scrollWidth;
            const containerWidth = containerRef.current.clientWidth;

            if (textWidth > containerWidth) {
                setIsScrolling(true);
                setAnimationDone(false);
            }
        }
    };

    const handleMouseLeave = () => {
        if (isDesktop) {
            setIsScrolling(false);
            setAnimationDone(true);
        }
    };

    const defaultJournalTitle = "My Journal";

    return (
        <JournalNameContainer
            ref={containerRef}
            data-qa-id="journal-name-container"
            onClick={!disabled ? onClick : undefined}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isDesktop={isDesktop}
            sx={{ marginRight: isDesktop ? "inherit" : pxToRem(16), ...customStyles }}
        >
            <JournalNameText
                ref={textRef}
                isScrolling={isScrolling && !animationDone}
                isDesktop={isDesktop}
                wordLength={(journalName || defaultJournalTitle).length}
            >
                {(journalName || defaultJournalTitle)}
            </JournalNameText>
        </JournalNameContainer>
    );
});

JournalName.propTypes = {
    journalName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default JournalName;
