import PropTypes from 'prop-types';
// @mui
import { LinearProgress } from '@mui/material';

// ----------------------------------------------------------------------

ProgressLinear.propTypes = {
  progress: PropTypes.number,
};

export default function ProgressLinear({ progress, customStyles }) {
  return (
    <LinearProgress
      key={"primary"}
      color={"primary"}
      value={progress}
      variant="determinate"
      sx={{ width: 1, ...customStyles }}
    />
  );
}
