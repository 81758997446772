// @mui
import { alpha, styled } from '@mui/material/styles';
import { ListItemIcon, ListSubheader, ListItemButton } from '@mui/material';
// config
import { ICON, NAV } from '../../../config-global';
import { pxToRem } from '../../../theme/typography';

// ----------------------------------------------------------------------

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'caption' && prop !== 'variant',
})(({ active, disabled, depth, caption, variant, theme }) => {
  const isLight = theme.palette.mode === 'light';

  const subItem = depth !== 1;

  const containedVariant = variant === 'contained';
  const outlinedVariant = variant === 'outlined';

  const burgundyDisabledPrimaryStyle = {
    ...(containedVariant && {
      backgroundColor: theme.palette.button.disabled.containedBackground,
      color: theme.palette.common.white,
    }),
    ...(outlinedVariant && {
      backgroundColor: theme.palette.button.disabled.outlinedBackground,
      borderColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.contrastText,
    }),
  };

  const activeStyle = {
    ...(containedVariant && {

      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[0],
      background: theme.palette.background.lightNeutral,
      zIndex: 1,
      border: `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`,
      paddingLeft: pxToRem(14), // undoes extra padding from border
      backgroundColor: theme.palette.button.hover.containedBackground,
      color: theme.palette.common.white,
    }),
    ...(outlinedVariant && {
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[0],
      background: theme.palette.background.lightNeutral,
      zIndex: 1,
      border: `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`,
      paddingLeft: pxToRem(14), // undoes extra padding from border
      backgroundColor: theme.palette.button.hover.outlinedBackground,
      color: theme.palette.secondary.contrastText,

    }),
    ...(!isLight && {
      color: theme.palette.primary.light,
    }),
  };

  const activeHoverStyle = {
    ...(containedVariant && {
      backgroundColor: theme.palette.button.hover.containedBackground,
      boxShadow: 'inherit',
    }),
    ...(outlinedVariant && {
      backgroundColor: theme.palette.button.hover.outlinedBackground,
      color: theme.palette.secondary.contrastText,
    }),
    ...(!isLight && {
      color: theme.palette.primary.light,
    }),
  };

  const inActiveHoverStyle = {
    ...(containedVariant && {
      backgroundColor: theme.palette.button.hover.containedBackground,
      color: theme.palette.common.white,

    }),
    ...(outlinedVariant && {
      backgroundColor: theme.palette.button.hover.outlinedBackground,
      color: theme.palette.secondary.contrastText,
    }),
    ...(!isLight && {
      color: theme.palette.primary.light,
    }),
  };

  const activeSubStyle = {
    ...activeHoverStyle
  };

  return {
    position: 'relative',
    textTransform: 'capitalize',
    // paddingLeft: theme.spacing(1.5),
    // paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    height: NAV.H_DASHBOARD_ITEM,
    '&:hover': {
      ...inActiveHoverStyle
    },
    // Sub item
    ...(subItem && {
      height: NAV.H_DASHBOARD_ITEM_SUB,
      ...(depth > 2 && {
        paddingLeft: theme.spacing(depth),
      }),
      ...(caption && {
        height: NAV.H_DASHBOARD_ITEM,
      }),
    }),
    // Active item
    ...(active && {
      ...activeStyle,
      '&:hover': {
        ...activeHoverStyle,
      },
    }),
    // Active sub item
    ...(subItem &&
      active && {
      ...activeSubStyle,
      '&:hover': {
        ...activeHoverStyle,
      },
    }),
    // Disabled
    ...(disabled && {
      '&.Mui-disabled': {
        ...burgundyDisabledPrimaryStyle,
      },
    }),
  };
});

// ----------------------------------------------------------------------

export const StyledIcon = styled(ListItemIcon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: ICON.NAV_ITEM,
  height: ICON.NAV_ITEM,
});

// ----------------------------------------------------------------------

export const StyledDotIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active, theme }) => ({
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shorter,
  }),
  ...(active && {
    transform: 'scale(2)',
    backgroundColor: theme.palette.primary.main,
  }),
}));

// ----------------------------------------------------------------------

export const StyledSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  fontSize: 11,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
