import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledRoot = styled('main')(({ theme, isDesktop }) => ({
  padding: isDesktop ? theme.spacing(4, 6, 4, 6) : 0,
}));

export const StyledContent = styled('div')(({ isDesktop, theme }) => ({
  padding: isDesktop ? "0" : theme.spacing(2, 2, 0, 2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

