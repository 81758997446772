import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { GUMDROP_WRITING } from "../../constants/character-animation-events";
import CharacterAnimation from "../../animation/CharacterAnimation";
import { useStoresContext } from "../../stores/useStoresContext";
import { pxToRem } from '../../theme/typography';

// Grid background style
const gridBackground = {
  backgroundImage: `
    linear-gradient(to right, ${alpha("#A1D7FF", 0.7)} 0.6px, transparent 0.6px),
    linear-gradient(to bottom, ${alpha("#A1D7FF", 0.7)} 0.6px, transparent 0.6px)
  `,
  backgroundSize: "20px 20px",
};

// Root styling for the loading screen
const StyledRoot = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.palette.background.default,
  ...gridBackground,
}));

// Styling for the text section
const TextSection = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#FFFCDD",  // Solid background over the grid
  padding: `0  0 ${theme.spacing(3)} 0`,
  textAlign: "center",
}));

// Overline styling
const Overline = styled(Box)(({ theme }) => ({
  width: "100%",
  height: pxToRem(2),
  backgroundColor: "#E5B77E",
  marginBottom: theme.spacing(3),
}));

export default function LoadingScreen({ onLoadingComplete }) {
  const { isReady: storesAreReady, characterAnimationStore } = useStoresContext();
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      if (storesAreReady) {
        characterAnimationStore.updateAnimation(GUMDROP_WRITING);
      }
    };

    initialize();

    // reset on component unmount
    return () => {
      characterAnimationStore.reset();
    };
  }, [characterAnimationStore, storesAreReady]);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);

    if (onLoadingComplete) {
      onLoadingComplete();
    }
  };

  return (
    <StyledRoot data-qa-id="loading-screen-root">
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        data-qa-id="loading-screen-animation-container"
      >
        <CharacterAnimation
          animationData={characterAnimationStore.currentAnimationData}
          onFinish={onLoadingComplete ? handleAnimationComplete : undefined}
          loop={!onLoadingComplete} // Loop if onLoadingComplete is not defined
          data-qa-id="loading-screen-character-animation"
        />
      </Box>

      <TextSection data-qa-id="loading-screen-text-section">
        <Overline data-qa-id="loading-screen-overline" />
        <Typography
          variant="h3"
          sx={{ color: "#5E5038", fontWeight: "bold" }}
          data-qa-id="loading-screen-title"
        >
          Orchard Journal
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#5E5038" }}
          data-qa-id="loading-screen-subtitle"
        >
          Your OCD Journal
        </Typography>
      </TextSection>
    </StyledRoot>
  );
}
