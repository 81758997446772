import PropTypes from 'prop-types';
import { useContext } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, Card, Button, Avatar, CardHeader } from '@mui/material';
// context
import { RegistrationContext } from '../../RegistrationContext';
// styles
import { StyledRoot, StyledContent } from './styles';
// mock
import _mock from '../../../../../_mock';
// component
import FormProvider, { RHFTextField } from '../../../../../components/hook-form';
//hooks
import useResponsive from '../../../../../hooks/useResponsive';
import { handleError } from '../../../../../utils/handleError';

// ----------------------------------------------------------------------
const filename = "/sections/auth/register/forms/introduction-form/IntroductionForm.js";

// TODO: Update Avatars to be unique and original
export const _exampleIntroList = [...Array(2)].map((_, index) => ({
    id: _mock.id(index),
    name: _mock.name.firstName(index),
    description: _mock.text.exampleIntroductions(index),
    avatar: _mock.image.avatar(index),
}));

export default function IntroductionForm() {
    const { state, updateFields } = useContext(RegistrationContext);

    const isDesktop = useResponsive('up', 'lg');

    const IntroductionSchema = Yup.object().shape({
        introduction: Yup.string()
            .required('Introduction is required')
            .min(15, 'Mininum 15 characters')
            .max(1000, 'Maximum 1000 characters'),
    });

    const defaultValues = {
        introduction: state.introduction ?? '',
    };

    const methods = useForm({
        resolver: yupResolver(IntroductionSchema),
        defaultValues,
        mode: "onChange",
    });

    const {
        handleSubmit,
        formState: { isValid },
    } = methods;

    const onSubmit = async (data) => {
        try {
            updateFields({ introduction: data.introduction });
        } catch (error) {
            handleError({
                error: error,
                message: `Error submitting introduction`,
                filename,
                functionName: "onSubmit",
            });
        }
    };

    return (
        <StyledRoot isDesktop={isDesktop} data-qa-id="introduction-root-container">
            <StyledContent data-qa-id="introduction-content-container">
                <Stack spacing={isDesktop ? 4 : 2} sx={{ my: 4, width: 1 }} data-qa-id="introduction-content-container">
                    <Typography variant="h4" sx={{ textAlign: "left" }}>How should we introduce you?</Typography>
                    <FormProvider
                        methods={methods}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack sx={{ width: 1 }} spacing={2} data-qa-id="introduction-text-field-examples-container">
                            <Card sx={{ p: 3 }} data-qa-id="introduction-text-field-container">
                                <RHFTextField
                                    name="introduction"
                                    label="Introduction"
                                    multiline
                                    rows={3}
                                    autoFocus={true}
                                />
                            </Card>
                            <Card sx={{ maxWidth: "45rem" }} data-qa-id="introduction-examples-header-container">
                                <CardHeader
                                    title={"Example introductions"}
                                />
                                {_exampleIntroList.map((item) => (
                                    <ExampleIntro key={item.id} item={item} />
                                ))}
                            </Card>
                            <Button
                                fullWidth
                                color="inherit"
                                size="large"
                                type="submit"
                                variant="contained"
                                disabled={!isValid}
                                sx={{
                                    bgcolor: 'text.primary',
                                    color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                                    '&:hover': {
                                        bgcolor: 'text.primary',
                                        color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                                    },
                                }}
                                data-qa-id="introduction-next-button"
                            >
                                Next
                            </Button>
                        </Stack>
                    </FormProvider>
                </Stack>
            </StyledContent>
        </StyledRoot>
    );
}

// ----------------------------------------------------------------------

ExampleIntro.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        avatar: PropTypes.string,
        description: PropTypes.string,
    }),
};

function ExampleIntro({ item }) {
    const { avatar, name, description } = item;

    return (
        <Stack
            spacing={2}
            sx={{
                p: (theme) => theme.spacing(3, 3, 2, 3),
            }}
            data-qa-id="introduction-example-intro-container"
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar alt={name} src={avatar} />

                <div>
                    <Typography variant="subtitle2">{name}</Typography>
                </div>
            </Stack>

            <Typography variant="body2">{description}</Typography>
        </Stack>
    );
}