import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Lottie from "lottie-react";
import { handleError } from "../utils/handleError";
import gumDropWriting from "../animation-data/gumdrop_writing.json";

const filename = "src/animation/CharacterAnimation.js";

const CharacterAnimation = observer(({ animationData, loop = true, autoplay = true, style = {}, onFinish }) => {
    const handleErrorEvent = (error) => {
        handleError({
            error: error,
            message: `Error loading animation`,
            filename,
            functionName: "handleErrorEvent",
        });

        // Optionally, handle the error (e.g., set a fallback animation or notify the user)
    };

    return (
        <div style={style} data-qa-id={"lottie-player-container"}>
            <Lottie
                animationData={animationData || gumDropWriting}
                loop={loop}
                autoplay={autoplay}
                style={{ height: style.height || '100%', width: style.width || '100%' }}
                onComplete={onFinish}
                onError={handleErrorEvent}
            />
        </div>
    );
});

export default CharacterAnimation;
