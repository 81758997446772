import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

NavToggleButton.propTypes = {
    openNav: PropTypes.bool,
    onToggleNav: PropTypes.func,
    sx: PropTypes.object,
};

export default function NavToggleButton({ openNav, onToggleNav, sx, ...other }) {
    const isDesktop = useResponsive('up', 'lg');

    if (!isDesktop) {
        return null;
    }

    return (
        <IconButton
            size="small"
            onClick={onToggleNav}
            sx={{
                p: 0.5,
                top: 32,
                position: 'fixed',
                left: openNav ? NAV.W_DASHBOARD - 12 : NAV.W_DASHBOARD_MINI - 12,
                bgcolor: 'background.default',
                zIndex: (theme) => theme.zIndex.appBar + 1,
                border: (theme) => `dashed 1px ${theme.palette.divider}`,
                transition: 'left 0.3s',
                '&:hover': {
                    bgcolor: 'background.default',
                },
                ...sx,
            }}
            {...other}
        >
            <Iconify
                width={16}
                icon={openNav ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'}
            />
        </IconButton>
    );
}
