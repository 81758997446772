// questions
export const ANSWERED_QUESTION = "answered_question";
export const EDITED_ANSWER = "edited_answer";
export const CANCELLED_QUESTION_ANSWERING = "cancelled_question_answering";

// journal
export const UPDATED_JOURNAL_NAME = "update_journal_name";

// calendar
export const CHANGED_QUESTION_DAY_VIEW = "changed_question_day_view";
export const CHANGED_QUESTION_MONTH_VIEW = "changed_question_month_view";

// account
export const DELETED_ACCOUNT = "deleted_account";

// onboarding
export const SIGNING_IN_BY_ANSWERING = "signing_in_by_answering";
export const CREATING_ACCOUNT_BY_ANSWERING = "creating_account_by_answering";

export const SIGNING_IN_DEFAULT = "signing_in_default";
export const CREATING_ACCOUNT_DEFAULT = "creating_account_default";