import PropTypes from 'prop-types';
// @mui
import { List, Stack, Box } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import { pxToRem } from '../../../theme/typography';
import { NAV } from '../../../config-global';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
  unreadMessageCount: PropTypes.number,
};

export default function NavSectionVertical({ data, unreadMessageCount, sx, ...other }) {
  const { translate } = useLocales();

  return (
    <Stack sx={{ ...sx, height: `calc(100vh - ${pxToRem(NAV.H_DESKTOP_LOGO_HEADER + 24)})`, flexDirection: 'column' }} {...other}>
      <Box sx={{ flexGrow: 1 }}>
        {data.map((group) => {
          const key = group.subheader || group.items[0].title;

          return (
            <List key={key} disablePadding sx={{ px: 2 }} data-qa-id={"data-items-list"}>
              {group.subheader && (
                <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
              )}
              {group.items.map((list) => (
                <NavList
                  key={list.title + list.path}
                  unreadMessageCount={unreadMessageCount}
                  data={list}
                  depth={1}
                  hasChild={!!list.children}
                />
              ))}
            </List>
          );
        })}
      </Box>
      <Box>
        {data.map((group) => (
          group.footerItems && (
            <List key={group.footerItems[0].title} disablePadding sx={{ px: 2 }} data-qa-id={"footer-items-list"}>
              {group.footerItems.map((list) => (
                <NavList
                  key={list.title + list.path}
                  unreadMessageCount={unreadMessageCount}
                  data={list}
                  depth={1}
                  hasChild={!!list.children}
                />
              ))}
            </List>
          )
        ))}
      </Box>
    </Stack>
  );
}
