import React, { useContext } from 'react';
import { RegistrationContext, RegistrationProvider } from './RegistrationContext';
import { REGISTRATION_STEP } from './constants';
import { NameForm } from './forms';

// ----------------------------------------------------------------------

const StepsToFormMapper = (props) => {
  const { state } = useContext(RegistrationContext);

  switch (state.step) {
    case REGISTRATION_STEP.NAME_FORM:
      return <NameForm phone={props.phone} />;
    default:
      return null;
  }
}

export default function Register({ phone }) {
  return (
    <RegistrationProvider>
      <StepsToFormMapper phone={phone} />
    </RegistrationProvider>
  );
}
